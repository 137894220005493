@import 'breakpoints';
@import 'components/material-icons';

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-button {
	display: none;
}

::-webkit-scrollbar-track {
	border-radius: 3px;
	background-color: var(--secondary-color);
}

::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: var(--primary-highlight-color);
	min-height: 64px;
	min-width: 64px;
}

body {
	position: relative;
	width: 100%;
}

.wrapper {
	width: 100%;
	margin: 0 auto;
	max-width: 1200px;
}

@menuWidth: 250px;
@headerHeight: 55px;
@headerPadding: 8px;
@offcanvasTransition: 300ms ease;

#headercontainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: @headerHeight;
	background-color: white;
	z-index: 1;
	display: flex;
	align-items: stretch;
	.wrapper {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 0.1rem solid var(--border-color);
		& > * {
			padding: @headerPadding @headerPadding;
		}

		#menu-toggle {
			padding-left: 1rem;
			flex: 0 0 auto;
			@media @bp-desktop {
				width: @menuWidth;
			}
			display: flex;
			.mat-icon {
				margin-right: 0;
			}
		}
		#headerlogo {
			flex: 1 1 auto;
		}
	}
}

#site-canvas {
	height: calc(100% - @headerHeight);
	margin-top: @headerHeight;
	width: 100%;

	display: flex;
}

.mixin-visible-offcanvas-menu {
	-webkit-transform: unset;
	transform: unset;
}

.mixin-hidden-offcanvas-menu {
	-webkit-transform: translateX(-@menuWidth) rotateY(180deg);
	transform: translateX(-@menuWidth) rotateY(180deg);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

#menu {
	position: sticky;
	top: @headerHeight;

	flex: 0 0 auto;
	width: @menuWidth;

	.mixin-hidden-offcanvas-menu();

	-webkit-transition: @offcanvasTransition all;
	transition: @offcanvasTransition all;

	@media @bp-desktop {
		.mixin-visible-offcanvas-menu();
	}

	width: @menuWidth;
	height: 100%;
	left: -@menuWidth;
	padding: 1rem;
	@media @bp-desktop {
		left: 0;
	}
	max-height: calc(100vh - @headerHeight);
	overflow-y: auto;
}

.mixin-full-offcanvas-contentcontainer {
	-webkit-transform: translateX(-@menuWidth);
	transform: translateX(-@menuWidth);
}

.mixin-reduced-offcanvas-contentcontainer {
	-webkit-transform: unset;
	transform: unset;
}

#contentcontainer {
	flex: 0 0 auto;
	width: 100%;

	.mixin-full-offcanvas-contentcontainer();

	-webkit-transform: translateX(-@menuWidth);
	transform: translateX(-@menuWidth);

	-webkit-transition: @offcanvasTransition all;
	transition: @offcanvasTransition all;

	@media @bp-desktop {
		.mixin-reduced-offcanvas-contentcontainer();
		width: calc(100% - @menuWidth);
	}
}

#menu-toggle {
	.mat-icon {
		.mixin-icon('menu');
		@media @bp-desktop {
			.mixin-icon('menu_open');
		}
	}
}

body.show-offcanvas {
	#menu {
		.mixin-visible-offcanvas-menu();
	}
	#contentcontainer {
		.mixin-reduced-offcanvas-contentcontainer();
	}
	#menu-toggle {
		.mat-icon {
			.mixin-icon('menu_open');
		}
	}
}

@media @bp-desktop {
	body.hide-offcanvas {
		#menu {
			.mixin-hidden-offcanvas-menu();
		}
		#contentcontainer {
			.mixin-full-offcanvas-contentcontainer();
			width: 100%;
		}
		#menu-toggle {
			.mat-icon {
				.mixin-icon('menu');
			}
		}
	}
}

#content {
	padding: 1rem;
}

.mb-0 {
	margin-bottom: 0;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mt-0 {
	margin-top: 0;
}

.mt-1 {
	margin-top: 1rem;
}

.mt-2 {
	margin-top: 2rem;
}

.pb-0 {
	padding-bottom: 0;
}

.pb-1 {
	padding-bottom: 1rem;
}

.pb-1 {
	padding-bottom: 2rem;
}

.pt-0 {
	padding-top: 0;
}

.pt-1 {
	padding-top: 1rem;
}

.pt-1 {
	padding-top: 2rem;
}
