.startgruppen-liste {
	td {
		&.name {
			width: 30%;
		}
		&.badges {
			width: 50%;
		}
		&.actions {
			width: 20%;
		}
	}
}
