@import '../breakpoints';

.emailer {
	.recipient-container {
		display: grid;
		grid-template-columns: 1fr;
		@media @bp-tablet {
			grid-template-columns: 1fr 1fr;
		}
		grid-gap: 0.25rem;
		line-height: 1rem !important;
		.recipient {
			cursor: pointer;
			padding: 0.25rem;
			border: var(--border-width) solid var(--border-color);
			border-radius: var(--border-radius);
			display: grid;
			grid-template-columns: 1fr 5fr;
			grid-template-areas:
				"control name name name name name"
				"control info info info info info";
			.control {
				grid-area: control;
			}
			.name {
				grid-area: name;
			}
			.info {
				grid-area: info;
			}
		}
	}
}
