@import 'mixins/input';
@import 'mixins/chosen';
@import 'breakpoints';
@import 'variables';

.mixin-form-container() {
	&.form-bordered {
		border: var(--border-width) solid var(--border-color);
		border-radius: var(--border-radius);
		padding: 1rem;
	}
}

.form {
	margin-bottom: 1rem;

	.mixin-form-container();

	fieldset, .fieldset {
		padding: 1rem 0 0 0;
		margin: 0 0 1rem 0;
		width: 100%;
		max-width: 100%;
		overflow-x: clip;
		overflow-y: visible;
		border: 0;
		&:last-child {
			margin-bottom: 0;
		}

		legend, .legend {
			font-weight: bolder;
			font-size: 1.1rem;
			padding: 0 0.2rem;
			width: 100%;
			max-width: 100%;
			display: block;
		}
	}
	.fieldset {
		padding-top: 0;
	}

	&.form-condensed {
		.form-group {
			margin-bottom: 0.2rem;
			&:first-child {
				margin-top: 0.2rem;
			}
		}
	}

	.w-75 {
		@media @bp-tablet {
			width: 75% !important;
		}
	}

	.w-50 {
		@media @bp-tablet {
			width: 50% !important;
		}
	}

	.w-25 {
		@media @bp-tablet {
			width: 25% !important;
		}
	}

	input[type=submit] {
		.mixin-button-primary();
	}

	input[type=reset] {
		.mixin-button-secondary();
	}

	.form-group {
		display: flex;
		flex-wrap: wrap;
		@media @bp-tablet {
			flex-wrap: nowrap;
		}
		margin-bottom: @formSpacing;
		&.full {
			flex-wrap: wrap;
		}
		&:first-child {
			margin-top: @formSpacing;
		}
		&:last-child {
			margin-bottom: 0;
		}

		.form-label {
			font-weight: bolder;
			flex: 1 0 100%;
			text-align: left;
			@media @bp-tablet {
				flex: 0 0 25%;
				text-align: right;
			}
			padding-right: 2 * @formSpacing;
			line-height: @formLineHeightDefault;
			&.light {
				font-weight: normal;
			}
		}

		.form-control {
			flex: 1 0 100%;
			@media @bp-tablet {
				flex: 0 0 75%;
			}
			line-height: @formLineHeightDefault;
		}

		.form-control-static {
			flex: 1 0 100%;
			@media @bp-tablet {
				flex: 0 0 75%;
			}
			line-height: @formLineHeightDefault;
		}

		.form-control, .form-control-static {
			&.offset-25 {
				@media @bp-tablet {
					margin-left: 25%;
				}
			}
		}

		&.full {
			.form-label, .form-control, .form-control-static, .container {
				flex: 0 0 100%;
			}
			.form-label {
				text-align: left;
			}
		}

		.form-control, .form-control-static {
			label {
				display: inline-block;
				margin-right: 1rem;
				&.disabled {
					color: var(--muted-color);
				}
			}
			&.check-container, &.radio-container, .check-container, .radio-container {
				display: grid;
				grid-template-columns: 1fr;
				@media @bp-tablet {
					&.horizontal {
						grid-template-columns: 1fr 1fr;
						> .error-block, > .invalid-feedback, >.help-block, >.form-text {
							grid-column: span 2;
						}
						.span-2 {
							grid-column: span 2;
						}
						.span-3 {
							grid-column: span 2;
						}
					}
				}
				@media @bp-desktop {
					&.horizontal {
						grid-template-columns: 1fr 1fr 1fr;
						> .error-block, > .invalid-feedback, >.help-block, >.form-text {
							grid-column: span 3;
						}
						.span-2 {
							grid-column: span 2;
						}
						.span-3 {
							grid-column: span 3;
						}
					}
				}
			}
		}

		&.error {
			.form-label {
				color: var(--error-button-bgcolor);
			}

			input[type=text], input[type=number], input[type=password], input[type=email], input[type=url], input[type=tel], input[type=date], input[type=datetime-local], input[type=time], select, textarea {
				border-color: var(--error-button-bgcolor);
				outline-color: var(--error-button-bgcolor);
			}

			.chosen-container {
				.chosen-single {
					border-color: var(--error-button-bgcolor);
				}
			}
		}

		&:has(.invalid-feedback p) {
			.form-label {
				color: var(--error-button-bgcolor);
			}
		}

		.help-block, .form-text {
			.mixin-input-message();
			color: #a0a0a0;
			margin: 0 @inputHorizontalMargin 0 @inputHorizontalMargin;
		}

		.error-block, .invalid-feedback {
			.mixin-input-message();
			color: var(--error-button-bgcolor);
			margin: 0 @inputHorizontalMargin 0 @inputHorizontalMargin;
		}

		.invalid-feedback {
			p {
				margin-bottom: 0;
			}
		}
	}
}
