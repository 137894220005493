.vereins-liste {
	td {
		&.name {
			width: 50%;
		}
		&.badges {
			width: 20%;
		}
		&.user-count {
			width: 10%;
		}
		&.actions {
			width: 20%;
		}
	}
}
