.pager {
	padding: 1rem;
	text-align: center;

	a {
		display: inline-block;
		margin-right: 0.3rem;
		margin-bottom: 0.3rem;
		width: 2.4rem;
		line-height: 2.0rem;
		border: var(--border-width) solid var(--border-color);
		border-radius: var(--border-radius);
		text-decoration: none;

		&:last-child {
			margin-right: 0;
		}

		&.active {
			&:hover {
				background-color: lighten(@secondaryButtonColor, 10%);
				border-color: lighten(@secondaryButtonColor, 10%);
				transition: background-color ease-in-out .15s;
			}
		}

		&.inactive {
			cursor: not-allowed;
		}

		&.current {
			font-weight: bold;
			color: var(--primary-text-color);
			background-color: var(--primary-color);
			border-color: var(--primary-color);

			&:hover {
				background-color: var(--primary-highlight-color);
				border-color: var(--primary-highlight-color);
				transition: background-color ease-in-out .15s;
			}
		}
	}
}
