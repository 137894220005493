.mixin-icon(@name) {
	&:before {
		@url: "../../../../../../node_modules/@material-design-icons/svg/filled/@{name}.svg";
		-webkit-mask: url("@{url}") no-repeat 50%;
		-webkit-mask-size: 100%;
		mask: url("@{url}") no-repeat 50%;
		mask-size: 100%;
	}
}
.mixin-local-icon(@name) {
	&:before {
		@url: "../../../icons/@{name}.svg";
		-webkit-mask: url("@{url}") no-repeat 50%;
		-webkit-mask-size: 100%;
		mask: url("@{url}") no-repeat 50%;
		mask-size: 100%;
	}
}

.mat-icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	vertical-align: -10%;
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background-color: var(--primary-color);
	}
	&.icon-success {
		&:before {
			background-color: var(--success-text-color);
		}
	}
	&.icon-warning {
		&:before {
			background-color: var(--warning-button-bgcolor);
		}
	}
	&.icon-error {
		&:before {
			background-color: var(--error-button-bgcolor);
		}
	}
	&.icon-muted {
		&:before {
			background-color: var(--muted-color);
		}
	}
	&.icon-black {
		&:before {
			background-color: black;
		}
	}

	&.add  {
		.mixin-icon('add');
	}
	&.home  {
		.mixin-icon('home');
	}
	&.menu  {
		.mixin-icon('menu');
	}
	&.close  {
		.mixin-icon('close');
	}
	&.done  {
		.mixin-icon('done');
	}
	&.delete  {
		.mixin-icon('delete');
	}
	&.cancel  {
		.mixin-icon('cancel');
	}
	&.check_circle  {
		.mixin-icon('check_circle');
	}
	&.add_circle  {
		.mixin-icon('add_circle');
	}
	&.info  {
		.mixin-icon('info');
	}
	&.error  {
		.mixin-icon('error');
	}
	&.warning  {
		.mixin-icon('warning');
	}
	&.block  {
		.mixin-icon('block');
	}
	&.error_outline  {
		.mixin-icon('error_outline');
	}
	&.file_download  {
		.mixin-icon('file_download');
	}
	&.email  {
		.mixin-icon('email');
	}
	&.list  {
		.mixin-icon('list');
	}
	&.arrow_upward  {
		.mixin-icon('arrow_upward');
	}
	&.arrow_circle_up  {
		.mixin-icon('arrow_circle_up');
	}
	&.arrow_downward  {
		.mixin-icon('arrow_downward');
	}
	&.arrow_circle_down  {
		.mixin-icon('arrow_circle_down');
	}
	&.save  {
		.mixin-icon('save');
	}
	&.woman  {
		.mixin-icon('woman');
	}
	&.man  {
		.mixin-icon('man');
	}
	&.public  {
		.mixin-icon('public');
	}
	&.comment  {
		.mixin-icon('comment');
	}
	&.edit  {
		.mixin-icon('edit');
	}
	&.block  {
		.mixin-icon('block');
	}
	&.question_mark  {
		.mixin-icon('question_mark');
	}
	&.check  {
		.mixin-icon('check');
	}
	&.event_available  {
		.mixin-icon('event_available');
	}
	&.event_busy  {
		.mixin-icon('event_busy');
	}
	&.account_circle  {
		.mixin-icon('account_circle');
	}
	&.arrow_back  {
		.mixin-icon('arrow_back');
	}
	&.arrow_forward  {
		.mixin-icon('arrow_forward');
	}
	&.person  {
		.mixin-icon('person');
	}
	&.star  {
		.mixin-icon('star');
	}
	&.restore  {
		.mixin-icon('restore');
	}
	&.drag_handle {
		.mixin-icon('drag_handle');
	}
	&.drag_indicator {
		.mixin-icon('drag_indicator');
	}
	&.description {
		.mixin-icon('description');
	}
	&.send {
		.mixin-icon('send');
	}
	&.edit_note {
		.mixin-icon('edit_note');
	}
	&.change_circle {
		.mixin-icon('change_circle');
	}
	&.insert_invitation {
		.mixin-icon('insert_invitation');
	}
	&.search {
		.mixin-icon('search');
	}
	&.login {
		.mixin-icon('login');
	}
	&.logout {
		.mixin-icon('logout');
	}
	&.menu_open {
		.mixin-icon('menu_open');
	}
	&.remove_circle {
		.mixin-icon('remove_circle');
	}
	&.find_replace {
		.mixin-icon('find_replace');
	}
	&.upload_file {
		.mixin-icon('upload_file');
	}
	&.arrow_drop_down {
		.mixin-icon('arrow_drop_down');
	}
	&.arrow_drop_down_circle {
		.mixin-icon('arrow_drop_down_circle');
	}
	&.pending {
		.mixin-icon('pending');
	}
	&.splitscreen {
		.mixin-icon('splitscreen');
	}
	&.construction {
		.mixin-icon('construction');
	}
	&.notifications {
		.mixin-icon('notifications');
	}
	&.content_copy {
		.mixin-icon('content_copy');
	}
	&.loop {
		.mixin-icon('loop');
		&.animated {
			@-moz-keyframes spin {
				100% { -moz-transform: rotate(-360deg); }
			}
			@-webkit-keyframes spin {
				100% { -webkit-transform: rotate(-360deg); }
			}
			@keyframes spin {
				100% {
					-webkit-transform: rotate(-360deg);
					transform:rotate(-360deg);
				}
			}
			-webkit-animation:spin 1s linear infinite;
			-moz-animation:spin 1s linear infinite;
			animation:spin 1s linear infinite;
		}
	}
	&.import_export {
		.mixin-icon('import_export');
	}
}
