.table-wrapper {
	width: 100%;
	overflow-x: auto;
	table {
		width: 100%;
	}
}

table {
	&.list_table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0;
		th, td {
			padding: 0.6rem;
			vertical-align: top;
			line-height: 175%;
		}
		th {
			text-align: left;
		}
	}
	&.table-bordered {
		thead {
			th {
				border-bottom: calc(2 * var(--border-width)) solid var(--border-color);
			}
		}
		th, td {
			border-bottom: var(--border-width) solid var(--border-color);
		}
		tr:last-child, tr.no-border {
			td {
				border-bottom: none;
			}
		}
	}
	&.table-outlined {
		border: var(--border-width) solid var(--border-color);
		border-radius: var(--border-radius);
	}
	&.table-condensed {
		th, td {
			padding: 0.3rem;
		}
	}
	&.table-striped {
		tbody {
			tr:nth-child(even) {
				td {
					background-color: @tableStripeColorOdd;
				}
			}
			tr:nth-child(odd) {
				td {
					background-color: @tableStripeColorEven;
				}
			}
		}
		tr:nth-child(even) {
			td {
				background-color: @tableStripeColorEven;
			}
		}
		tr:nth-child(odd) {
			td {
				background-color: @tableStripeColorOdd;
			}
		}
	}
	&.table-aligned {
		td:first-child, th:first-child {
			padding-left: 0;
		}
		td:last-child, th:last-child {
			padding-right: 0;
		}
	}
	&.table-nowrap {
		td, th {
			white-space: nowrap;
		}
	}
	td, th {
		&.nowrap {
			white-space: nowrap;
		}
	}
}
