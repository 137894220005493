.card {
	.card-header {
		width: 100%;
		h1, h2, h3, h4, h5, h6 {
			margin: 0;
		}
	}
	.card-body {
		width: 100%;
	}
	.card-footer {
		width: 100%;
	}
}
