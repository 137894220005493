.card {
	@padding: 1rem;

	border: var(--border-width) solid var(--border-color);
	border-radius: var(--border-radius);
	margin-bottom: 1rem;

	.card-header {
		background-color: white;
		padding: @padding;

		h1, h2, h3, h4, h5, h6 {
			color: var(--primary-color);
		}
	}

	.card-body {
		padding: 0 @padding @padding @padding;
		line-height: 1.5rem;
	}

	.card-footer {
		padding: 0 @padding @padding @padding;
	}
}

.card-grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem;
	margin-bottom: 1rem;

	@media @bp-tablet {
		grid-template-columns: 1fr 1fr;
	}
	@media @bp-desktop {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.span-tablet-2 {
		@media @bp-tablet {
			grid-column: span 2;
		}
	}

	.span-desktop-3 {
		@media @bp-desktop {
			grid-column: span 3;
		}
	}
}
