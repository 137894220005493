.modal {
	&.wrdetails {
		.modal-content {
			min-height: 400px;
			max-height: 100%;
		}
		.assignments-pager {
			display: flex;
			div  {
				flex: 1 0 50%;
			}
		}
	}
}
