.veranstaltungs-editor {
	.competition-table {
		@keyframes moving {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
		@keyframes moved {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}
		min-width: 750px;
		tbody {
			tr {
				&.moving {
					animation-name: moving;
					animation-duration: 500ms;
					animation-iteration-count: 1;
				}
				&.moved {
					animation-name: moved;
					animation-duration: 500ms;
					animation-duration-count: 1;
				}
				&.dragging {
					opacity: 0.25;
					transition: opacity 200ms ease;
				}
				&.droptarget {
					td {
						background-color: lightblue;
					}
				}
			}
		}
		.datetime-controls {
			div {
				display: flex;
				flex-direction: column;
				input {
					flex: 0 0 calc(33.33% - 0.5rem);
					margin-bottom: 0.25rem;
				}
			}
		}
		.competition-controls {
			div {
				display: flex;
				flex-direction: column;
				select {
					flex: 0 0 calc(33.33% - 0.5rem);
					margin-bottom: 0.25rem;
				}
			}
		}
		.competition-actions {
			line-height: 2.3rem;
			white-space: nowrap;
			.mat-icon {
				font-size: 1.5em;
			}
			.disabled {
				pointer-events: none;
				cursor: not-allowed;
				.mat-icon {
					&:before {
						background-color: var(--muted-color);
					}
				}
			}
		}
		.drag_handle {
			cursor: grab;
		}
		tfoot {
			tr:first-child {
				td {
					border-top: calc(4 * var(--border-width)) solid var(--border-color);
				}
			}
		}
	}
	.team-status-table {
		th, td {
			&.team {
				width: 40%;
				white-space: nowrap;
			}
			&.status {
				width: 15%;
			}
			&.planned {
				width: 15%;
			}
			&.open {
				width: 15%;
			}
			&.confirmed {
				width: 15%;
			}
		}
	}
	.assigmentplan-history-table {
		th, td {
			&.timestamp {
				width: 40%;
				white-space: nowrap;
			}
			&.status {
				width: 20%;
			}
			&.files {
				width: 40%;
				white-space: nowrap;
			}
		}
	}
	.attachment-table {
		th, td {
			&.file {
				width: 60%;
				white-space: nowrap;
			}
			&.options {
				width: 40%;
				white-space: nowrap;
			}
		}
	}
}
