.alert {
	border: var(--border-width) solid transparent;
	border-radius: var(--border-radius);
	padding: 1rem;
	margin-bottom: 1rem;
	p, ul {
		margin: 0 0 0.5rem 0;
		&:last-child {
			margin-bottom: 0;
		}
		line-height: 1.5rem;
	}
	a {
		text-decoration: underline;
	}
	&.alert-info {
		background-color: var(--info-bgcolor);
		color: var(--info-text-color);
		a {
			color: var(--info-text-color);
		}
	}
	&.alert-success {
		background-color: var(--success-bgcolor);
		color: var(--success-text-color);
		a {
			color: var(--success-text-color);
		}
	}
	&.alert-warning {
		background-color: var(--warning-bgcolor);
		color: var(--warning-text-color);
		a {
			color: var(--warning-text-color);
		}
	}
	&.alert-error {
		background-color: var(--error-bgcolor);
		color: var(--error-text-color);
		a {
			color: var(--error-text-color);
		}
	}
}
