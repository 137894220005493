.wertungsrichter-liste {
	td {
		&.name {
			width: 25%;
		}
		&.user-id {
			width: 15%;
		}
		&.stats {
			width: 15%;
		}
		&.verein {
			width: 35%;
		}
		&.state {
			width: 10%;
		}
	}
}

.systembenutzer-liste {
	td {
		&.name {
			width: 25%;
		}
		&.user-id {
			width: 15%;
		}
		&.roles {
			width: 15%;
		}
		&.mandators {
			width: 35%;
		}
		&.state {
			width: 10%;
		}
	}
}
