@import '../mixins/input';

.freigabe_form {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	flex-wrap: wrap;

	.freigabe {
		flex: 0 0 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-end;
		.freigabe_kalender {
			flex: 0 0 100%;
		}
		.freigabe_speichern {
			flex: 0 0 100%;
		}
	}
	.freigabe_legende {
		flex: 0 0 100%;
	}
}

.freigabe_kalender, .freigabe_legende {
	border-collapse: collapse;

	th, td {
		text-align: center;
	}

	th {
		color: var(--primary-color);
	}

	td {
		padding: 0.5rem;

		span {
			display: block;
			width: 2.5rem;
			height: 2.5rem;
			line-height: 2.4rem;
			margin: 0 auto;
			border: var(--border-width) solid var(--border-color);
			border-radius: var(--border-radius);
			vertical-align: middle;
//			padding: 1rem;
			color: var(--border-color);
		}

		&.monat {
			span {
				color: var(--text-color);
			}
		}
	}

	input[type=submit] {
		.mixin-button-secondary();
	}

	.nav-button {
		width: 50%;
	}
}

.freigabe_legende {
	margin-top: 1rem;
	th {
		text-align: left;
	}
}

.freigabe-speichern {
	flex: 0 0 100%;
	text-align: center;
	padding: 0.5rem;
	input[type=submit] {
		.mixin-button-primary()
	}
}

table.freigabe_kalender td {
//	vertical-align: middle;
	//	text-align: center;
}

table.freigabe_kalender td.kalender {
}

table.freigabe_kalender td.kalender span, table.freigabe_legende td.kalender span {
}

table.freigabe_legende td {
}

table.freigabe_kalender td.monat span {
}

table.freigabe_kalender td.selectable span, table.freigabe_legende td.selectable span {
	background-color: #E5FFD6;
}

table.freigabe_kalender td.selectable span {
	cursor: pointer;
}

table.freigabe_kalender td.free span, table.freigabe_legende td.free span {
	background-color: #afff44;
}

table.freigabe_kalender td.free span {
	cursor: pointer;
}

table.freigabe_kalender td.invited span, table.freigabe_legende td.invited span {
	background-color: #FFFF80;
}

table.freigabe_kalender td.confirmed span, table.freigabe_legende td.confirmed span {
	background-color: #CCFFFF;
}

table.freigabe_kalender td.cancelled span, table.freigabe_legende td.cancelled span {
	background-color: #FF8080;
}

table.freigabe_kalender {
	.nav-button {
	}
	.header {
		font-weight: bold;
	}
}

.freigabe-speichern {
//	text-align: right;
}
