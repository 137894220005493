.lizenzen-liste {
	td {
		&.name {
			width: 25%;
		}
		&.type {
			width: 15%;
		}
		&.only-effective {
			width: 20%;
		}
		&.badges {
			width: 20%;
		}
		&.priority {
			width: 20%;
		}
	}
}
