.progress {
	@keyframes progress-indeterminate {
		from {
			width: 0;
		}
		to {
			width: 100%;
		}
	}

	margin: 1rem auto;
	padding: 0.1rem;
	width: 100%;
	max-width: 320px;
	border: 0.1rem solid var(--border-color);
	border-radius: var(--border-radius);
	height: 1rem;

	&.hidden {
		display: none;
	}

	&.absolute {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);
	}

	.progress-indeterminate {
		height: 100%;
		width: 0;
		background-color: var(--primary-color);
		border: 0.1rem solid transparent;
		border-radius: var(--border-radius);
		animation: progress-indeterminate 3s infinite;
	}
}

.list-actions {
	.progress {
		&.absolute {
			margin: 0.5rem auto;
			top: unset;
			bottom: 0;
		}
	}
}

.form-control, .form-control-static {
	.progress {
		margin: 0.5rem auto;
	}
}
