@import '../../modern/breakpoints';

.dashboard {
	width: 100%;
	overflow: hidden;
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr;
	grid-template-areas:
		"news"
		"team-audit"
		"event-audit"
		"replies"
		"user-events";

	@media @bp-desktop {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"news news"
			"team-audit event-audit"
			"replies replies"
			"user-events user-events";
	}

	.card {
		margin-bottom: 0;
	}

	.news {
		grid-area: news;
	}
	.team-audit {
		grid-area: team-audit;
	}
	.event-audit {
		grid-area: event-audit;
	}
	.replies {
		grid-area: replies;
	}
	.user-events {
		grid-area: user-events;
	}
	.news {
		.data {
			display: grid;
			grid-template-columns: 1fr 2fr;
			@media @bp-tablet {
				grid-template-columns: 1fr 6fr;
			}
		}
	}
	.event-audit,.team-audit {
		.data {
			display: grid;
			grid-template-columns: 1fr 1fr 3fr;
		}
	}
	.replies, .user-events {
		.data {
			display: grid;
			grid-template-columns: 1fr 1fr;
			.entry {
				grid-column: span 2;
			}
			@media @bp-tablet {
				grid-template-columns: 1fr 1fr 3fr;
				.entry {
					grid-column: span 1;
				}
			}
		}
	}

	.label {
		display: inline-block;
		text-align: center;

		&.inverted {
			color: white;
		}

		&.cancelled {
			background-color: #FF9999;
		}

		&.confirmed {
			background-color: #66FF99;
		}

		&.activated {
			background-color: #66FF99;
		}

		&.validated {
			background-color: lightblue;
		}

		&.invited {
			background-color: lightblue;
		}

		&.default {
			background-color: #d3d3d3;
		}

		&.registered {
			background-color: orange;
		}

		&.team-missing {
			background-color: #FF9999;
		}

		&.plan-missing {
			background-color: orange;
		}

		&.assignments-missing {
			background-color: #FF9999;
		}

		&.invitations-missing {
			background-color: orange;
		}

		&.confirmations-missing {
			background-color: #cc6699;
		}
	}

	a[onclick] {
		cursor: pointer;
	}
}
