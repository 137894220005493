.veranstaltungs-liste {
	.mat-icon {
		font-size: 1.5em;
	}
	.highlight-error {
		color: var(--error-button-bgcolor);
		font-weight: bolder;
	}
	.mixin-checkbox-default();
}
