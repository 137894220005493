.badge {
	padding: 0.2em 0.5em;
	border: var(--border-width) solid transparent;
	border-radius: var(--border-radius);
	font-size: 0.9em;
	white-space: nowrap;
}

.badge-error, .badge-state--1 {
	color: var(--error-text-colorr);
	background-color: var(--error-bgcolor);
}

.badge-warning, .badge-state-1 {
	color: var(--warning-text-color);
	background-color: var(--warning-bgcolor);
}

.badge-default, .badge-state-0 {
	color: var(--text-color);
	background-color: var(--border-color);
}

.badge-info {
	background-color: var(--info-bgcolor);
	color: var(--info-text-color);
}

.badge-success, .badge-state-2 {
	background-color: var(--success-bgcolor);
	color: var(--success-text-color);
}
