.mixin-chosen-default(@height) {
	.chosen-container {
		font-size: @fontSize;

		.chosen-single {
			&:focus-within {
				outline: solid 0.1rem var(--link-hover-color);
			}
			border: var(--border-width) solid var(--border-color);
			border-radius: var(--border-radius);
			height: @height;
			background: white;
			box-shadow: none;

			span {
				line-height: 2.1rem;
			}

			div {
				b {
					background-position-y: 0.5rem;
				}
			}

			abbr {
				top: 0.75rem;
			}
		}
	}
	.chosen-container-active.chosen-with-drop {
		.chosen-single {
			div {
				b {
					background-position-y: 0.5rem;
				}
			}
		}
	}
}
