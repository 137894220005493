@import '../breakpoints';

#menu-toggle {
	@lineHeight: 3px;
	@lineMargin: 6px;

	@buttonHeight: (2 * (@lineHeight + (2 * @lineMargin))) + @lineHeight;
	@overallHeight: @buttonHeight + (2 * @headerPadding);

	@additionalPadding: (@headerHeight - @overallHeight) / 2;

	padding-top: calc(@headerPadding + @additionalPadding) !important;
	padding-bottom: calc(@headerPadding + @additionalPadding) !important;
	height: @headerHeight;

	.menu-button {
		height: @buttonHeight;

		&:hover {
			cursor: pointer;
		}

		.line {
			width: 35px;
			height: @lineHeight;
			background-color: var(--primary-color);
			display: block;
			margin: @lineMargin auto;
			-webkit-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
		}

		&.toggled {
			.line:nth-child(1){
				opacity: 0;
				-webkit-transform: translateX(-100%);
				-ms-transform: translateX(-100%);
				-o-transform: translateX(-100%);
				transform: translateX(-100%);
			}
			.line:nth-child(3){
				opacity: 0;
				-webkit-transform: translateX(100%);
				-ms-transform: translateX(100%);
				-o-transform: translateX(100%);
				transform: translateX(100%);
			}
		}

		@media @bp-desktop {
			.line:nth-child(1){
				opacity: 0;
				-webkit-transform: translateX(-100%);
				-ms-transform: translateX(-100%);
				-o-transform: translateX(-100%);
				transform: translateX(-100%);
			}
			.line:nth-child(3){
				opacity: 0;
				-webkit-transform: translateX(100%);
				-ms-transform: translateX(100%);
				-o-transform: translateX(100%);
				transform: translateX(100%);
			}
			&.toggled {
				.line:nth-child(1){
					opacity: 1;
					-webkit-transform: translateX(0%);
					-ms-transform: translateX(0%);
					-o-transform: translateX(0%);
					transform: translateX(0%);
				}
				.line:nth-child(3){
					opacity: 1;
					-webkit-transform: translateX(0%);
					-ms-transform: translateX(0%);
					-o-transform: translateX(0%);
					transform: translateX(0%);
				}
			}
		}
	}
	span {
		color: var(--primary-color);
		font-size: 1.8em;
		padding-left: 0.5em;
		line-height: @buttonHeight;
		font-weight: 500;
	}
}
