@import '../breakpoints';

.sidenav {
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0 0 2rem 0;

	.mobile-only {
		@media @bp-desktop {
			display: none;
		}
	}

	li {
		list-style: none;
		ul {
			margin: 0;
			padding: 0;
		}
	}

	a {
		display: block;
		padding: 0.4rem 0.8rem;
		text-decoration: none;
		border: var(--border-width) solid transparent;
		border-radius: var(--border-radius);
		color: var(--text-color);
		&:hover {
			background-color: var(--primary-color);
			color: var(--primary-text-color);
			transition: background-color ease-in-out .15s, color ease-in-out .15s;
		}
	}

	> li {
		margin-bottom: 1rem;
		> a {
			font-size: 1.2em;
			&:before {
				width: 0;
				height: 0;
				display: inline-block;
				border: 6px solid transparent;
				content: "";
			}
		}
		&.active {
			> a {
				&:before {
					border-left-color: currentColor;
				}
			}
		}

		ul {
			&.menu_level_1 {
				display: none;
				li {
					padding-left: 0.5rem;
					> a {
						&:before {
							width: 0;
							height: 0;
							display: inline-block;
							border: 5px solid transparent;
							content: "";
						}
					}
					&.active, &.current_ancestor {
						> a {
							&:before {
								border-left-color: currentColor;
							}
						}
					}
				}
			}
		}

		&.active, &.current_ancestor {
			ul {
				&.menu_level_1 {
					display: block;
				}
			}
		}
	}

	.menu-impressum, .menu-datenschutz {
		margin-bottom: 0.25rem;
		a {
			font-size: 1em;
		}
	}
}

.supportnav {
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0 0 0.5rem 0;

	li {
		list-style: none;
		border: var(--border-width) solid transparent;
		border-radius: var(--border-radius);
		margin-bottom: 0.5rem;
		&:last-child {
			margin-bottom: 0;
		}
		&.active {
			background-color: var(--secondary-color);
			color: var(--secondary-text-color);
			a  {
				color: var(--secondary-text-color) !important;
			}
			&:hover {
				background-color: var(--secondary-color-highlight-color);
				transition: background-color ease-in-out .15s;
			}
		}
		a {
			display: block;
			padding: 0.4rem 0.8rem;
			text-decoration: none;
			font-weight: bolder;
		}
	}
}
