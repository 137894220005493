.wr-competition-table {
	td {
		word-break: break-word;
		&.date {
			width: 30%;
		}
		&.time {
			width: 10%;
		}
		&.title {
			width: 30%;
		}
		&.remarks {
			width: 30%;
		}
	}
}
