.tab-content {
	.tab-pane {
		display: none;
		padding: 1rem;
		margin-bottom: 1rem;
		&.active {
			display: block;
			border: var(--border-width) solid var(--border-color);
			border-radius: 0 0 var(--border-radius);
			border-top: 0;
		}
	}
}
