.turnierstaetten-liste  {
	td {
		&.name {
			width: 50%;
		}
		&.badges {
			width: 25%;
		}
		&.actions {
			width: 25%;
		}
	}
}
