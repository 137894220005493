@import 'mixins/input';

.btn-primary {
	.mixin-button-primary();
}
a.btn-primary {
	line-height: 2rem;
}

.btn-secondary {
	.mixin-button-secondary();
}
a.btn-secondary {
	line-height: 2rem;
}

.btn-error {
	.mixin-button-error();
}
a.btn-error {
	line-height: 2rem;
}

.btn-warning {
	.mixin-button-warning();
}
a.btn-warning {
	line-height: 2rem;
}

.btn-success {
	.mixin-button-success();
}
a.btn-success {
	line-height: 2rem;
}
