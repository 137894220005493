.topnav {
	display: flex;
	justify-content: flex-end;
	padding: 0;
	margin: 0;
	li {
		list-style: none;
		border: var(--border-width) solid transparent;
		border-radius: var(--border-radius);
		margin: 0 0.5rem 0 0;
		&:last-child {
			margin-right: 0;
		}
		&.active {
			background-color: var(--primary-color);
			color: var(--primary-text-color);
			a  {
				color: var(--primary-text-color);
			}
			&:hover {
				background-color: var(--primary-highlight-color);
				transition: background-color ease-in-out .15s;
			}
		}
		a {
			display: block;
			padding: 0.4rem 0.8rem;
			text-decoration: none;
			font-weight: bolder;
		}
	}
}
