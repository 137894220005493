.toast-container {
	@keyframes toast-fade-in {
		from {
			opacity: 0
		}
		to {
			opacity: 1
		}
	}

	position: fixed;
	bottom: 0;
	right: 0;
	width: 350px;
	padding: 1rem;

	display: flex;
	flex-direction: column-reverse;

	.toast {
		border-width: var(--border-width);
		border-radius: var(--border-radius);
		padding: 1rem;
		flex: 0 0 150px;
		margin-bottom: 1rem;
		z-index: 2;
		animation: toast-fade-in;
		animation-duration: 0.4s;
		position: relative;

		.close {
			position: absolute;
			top: 0.5rem;
			right: 1rem;
			color: darkgrey;
			float: right;
			font-size: 28px;
			font-weight: bold;
			&:hover, &:focus {
				color: #000;
				text-decoration: none;
				cursor: pointer;
			}
		}

		&:first-child {
			margin-bottom: 0;
		}

		a {
			text-decoration: underline;
		}
		p {
			word-break: break-word;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&.toast-info {
			background-color: var(--info-bgcolor);
			color: var(--info-text-color);
			a, .close {
				color: var(--info-text-color);
			}
		}
		&.toast-success {
			background-color: var(--success-bgcolor);
			color: var(--success-text-color);
			a, .close {
				color: var(--success-text-color);
			}
		}
		&.toast-warning {
			background-color: var(--warning-bgcolor);
			color: var(--warning-text-color);
			a, .close {
				color: var(--warning-text-color);
			}
		}
		&.toast-error {
			background-color: var(--error-bgcolor);
			color: var(--error-text-color);
			a, .close {
				color: var(--error-text-color);
			}
		}
	}
}
