@import '../breakpoints';

.image-resource-form {
	img {
		border: var(--border-width) solid var(--border-color);
	}
	.form-container {
		border: var(--border-width) solid var(--border-color);
		border-radius: var(--border-radius);
		padding: 1rem;

		display: grid;
		grid-template-columns: 1fr;
		@media @bp-tablet {
			grid-template-columns: 1fr 1fr;
		}
		grid-gap: 0.25rem;
	}
}
