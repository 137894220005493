@import '../breakpoints';

.modal {
	@keyframes modal-fade-in {
		from {
			opacity: 0
		}
		to {
			opacity: 1
		}
	}

	display: none;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
	&.open {
		display: block;
	}

	.modal-content {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: #fefefe;
		margin: auto;
		padding: 0;
		border: var(--border-width) solid var(--border-color);
		border-radius: var(--border-radius);

		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		animation-name: modal-fade-in;
		animation-duration: 0.4s;

		display: flex;
		flex-direction: column;

		width: 90%;
		max-height: 95vh;
		@media @bp-tablet {
			width: 75%;
		}
		@media @bp-desktop {
			width: 50%;
			max-width: 1200px;

			&.modal-small {
				width: 25%;
			}

			&.modal-large {
				width: 75%;
			}
		}

		.modal-header {
			padding: 1rem 1rem 0.5rem 1rem;
			&:not(.plain) {
				border-bottom: var(--border-width) solid var(--border-color);
			}
			flex: 0 0 max-content;

			.close {
				color: darkgrey;
				font-size: 1.3rem;
				line-height: 1.2;
				font-weight: bold;
				position: absolute;
				top: 1rem;
				right: 1rem;
				&:hover, &:focus {
					color: #000;
					text-decoration: none;
					cursor: pointer;
				}
			}
		}

		.modal-body {
			padding: 0.5rem 1rem 0.5rem 1rem;
			flex: 1 1 auto;
			overflow: auto;
			max-height: 65vh;
		}

		.modal-footer {
			padding: 0.5rem 1rem 1rem 1rem;
			&:not(.plain) {
				border-top: var(--border-width) solid var(--border-color);
			}
			flex: 0 0 max-content;
		}

		&.no-border {
			.modal-header {
				border-bottom: none;
			}
			.modal-footer {
				border-top: none;
			}
		}
	}
}

.modal.open + .modal.open {
	background-color: transparent;
}
