.dashboard {
	.label {
		padding: 0 0.5rem;
		min-width: 2rem;
		border-radius: var(--border-radius);
		font-weight: 400;
	}

	.data > div {
		margin-bottom: 1rem;
		&:not(:last-child) {
			padding-right: 0.4rem;
		}
	}

	img.mandant {
		@height: 1.33rem;

		height: @height;
		width: 1.33 * @height;
		vertical-align: top;
	}
}
