@import 'mixins/input';
@import 'variables';

input[type=text], input[type=number], input[type=password], input[type=email], input[type=url], input[type=tel], input[type=date], input[type=datetime-local], input[type=time], select, textarea {
	.mixin-input-default();
}

input[type=date], input[type=datetime-local], input[type=time] {
	appearance: none;
	-webkit-appearance: none;
}

input[type=text], input[type=password], input[type=number], input[type=email], input[type=date], input[type=time], select:not([multiple]) {
	height: @inputHeight;
}

.mixin-chosen-default(@inputHeight);

.chzn-select-deselect, .chzn-select {
	width: 100%;
}

.mixin-radio-default();

.mixin-checkbox-default();

textarea {
	line-height: 1.3rem;
}

input[type=file]::file-selector-button {
	.mixin-button-secondary();
	cursor: pointer;
}

.tinymce-wrapper {
	.tox-tinymce {
		border: var(--border-width) solid var(--border-color);
		border-radius: var(--border-radius);
		box-shadow: none;
	}
}

.svelte-select {
	&.focused {
		outline: @focusOutline;
	}
}
