.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;

	&.nav-tabs {
		.nav-item {
			margin-bottom: -1px;
		}
		.nav-link {
			display: block;
			padding: .5rem 1rem;
			border: var(--border-width) solid transparent;
			border-top-left-radius: var(--border-radius);
			border-top-right-radius: var(--border-radius);
			text-decoration: none !important;
			&.active {
				font-weight: bold;
				border-color: var(--border-color) var(--border-color) #fff;
				outline: 0 none;
			}
			&:hover {
				cursor: pointer;
			}
			&.disabled {
				color: var(--muted-color);
				&:hover {
					cursor: not-allowed;
				}
				outline: 0 none;
			}
			&:not(.active):hover {
				border-color: var(--border-color) var(--border-color) var(--border-color);
			}
		}
	}
}
